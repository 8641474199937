import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash.get'
import Layout from '../layouts/fr'
import SEO from '../components/seo'
import Home from '../views/Home'

const IndexPage = ({ pageContext, location, data }) => {
  const { langKey } = pageContext
  const homeData = get(data, 'prismic.allHomepages.edges[0].node')

  return (
    <Layout location={location}>
      <SEO title="Home" />
      <Home langKey={langKey} data={homeData} />
    </Layout>
  )
}

export const query = graphql`
  query {
    prismic {
      allHomepages(lang: "fr-fr") {
        edges {
          node {
            _meta {
              id
              uid
            }
            title
            intro_text
            intro_background
            intro_color
            last_item_color
            featured_articles {
              article {
                ... on PRISMIC_Article {
                  _meta {
                    id
                    uid
                  }
                  title
                  title_home
                  label
                  is_in_agenda
                }
              }
            }
          }
        }
      }
    }
  }
`

IndexPage.query = query

export default IndexPage
